
.user-message {
    background-color: #add8e6; /* Light blue for user */
    align-self: flex-start; /* Align to the left */
    margin-right: auto; /* Ensure the message does not extend to the right */
}

.ai-message {
    background-color: #d4edda; /* Light green for AI */
    align-self: flex-end; /* Align to the right */
    margin-left: auto; /* Ensure the message does not extend to the left */
}

.message-text {
    margin: 0;
}

.input-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.no-messages {
    text-align: center;
    color: #888;
}

.ask-flash-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%; /* Make the container take up full height */
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    box-sizing: border-box; /* Include padding and borders in the element’s width and height */
}

.chat-box {
    flex-grow: 1; /* Allow chat-box to grow and take as much vertical space as possible */
    overflow-y: auto;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.chat-message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    max-width: 85%;
    word-wrap: break-word;
}

.user-message {
    background-color: #add8e6;
    align-self: flex-start;
    margin-right: auto;
}

.ai-message {
    background-color: #d4edda;
    align-self: flex-end;
    margin-left: auto;
}

.message-text {
    margin: 0;
}

.input-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.no-messages {
    text-align: center;
    color: #888;
}