.progress-bar {
    width: 100%;
    background-color: #ddd;
    height: 20px;
    margin: 20px 0;
  }
  
.progress {
  height: 100%;
  background-color: #4CAF50;
  width: 0%;
}

.highlighted {
  background-color: #ffcccc; /* Light red background for visibility */
  padding: 0 2px; /* Optional padding to make the highlight look better */
}

.custom-underline {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.custom-underline::after {
  content: '';
  position: absolute;
  width: 80%;
  height: 1px;
  bottom: -2px;
  left: 10%;
  background-color: #000;
}