.lesson-container {
    padding: 20px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .meanings {
    margin-top: 20px;
  }
  
  .meaning {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  
  .details {
    display: none;
    margin-left: 20px;
  }
  
  .details.active {
    display: block;
  }
  
  .detail-section {
    margin-top: 15px;
    padding-left: 15px;
    border-left: 3px solid #ddd;
  }
  
  .context-item, .dialogue-item {
    margin-top: 10px;
    padding-left: 10px;
    border-left: 2px solid #ddd;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    margin-bottom: 5px;
  }
  
  /* New styles for reduced spacing between context, target-language, and source-language examples */
  .example {
    margin: 0 0 5px 0; /* Adjusts the distance between each example */
  }
  
  .context {
    margin: 0 0 5px 0;
  }
  
  .use-case {
    margin-bottom: 20px; /* Adjusts the distance between use cases */
  }