.progress-bar {
    width: 100%;
    background-color: #ddd;
    height: 20px;
    margin: 20px 0;
  }
  
.progress {
  height: 100%;
  background-color: #4CAF50;
  width: 0%;
}

.highlighted {
  background-color: #ffcccc; /* Light red background for visibility */
  padding: 0 2px; /* Optional padding to make the highlight look better */
}

.custom-underline-grammar-and-vocab {
  cursor: pointer;
  position: relative;
  display: inline-block;
  color: blue;
}

.custom-underline-grammar-and-vocab.highlighted {
  background-color: yellow; /* Optional: to highlight when clicked */
}

.custom-underline-grammar-and-vocab:hover {
  color: darkblue;
}

.progress-bar-container {
  position: relative;
  width: 100%;
  height: 30px; /* Adjust height as needed */
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0; /* Background color of the progress bar */
  position: relative;
}

.progress {
  height: 100%;
  background-color: #76c7c0; /* Progress color */
}

.progress-text {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.flashcard-background-container {
  background-color: #354f9c; /* Light background color for the main container */
  padding: 20px;
}

.custom-button {
  background-color: white !important;
  color: black !important; /* Matching text color with the container background */
  border: none;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.flashcard-container {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  /* background-color: #D3D3D3; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border: 2px solid #000000; /* Add border with your desired color */
}

.component-container {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border: 2px solid #000000; /* Add border with your desired color */
}


.translation-text {
  text-align: center;
  margin: 20px 0; /* Add margin to create a gap above and below the translation text */
}

.sentence-text {
  text-align: center;
  margin: 0 auto;
}

.difficulty-buttons {
  text-align: center;
}

.difficulty-buttons button {
  margin: 0 10px; /* Adjust the value to increase or decrease the gap */
}

.fc-buttons {
  text-align: center;
  margin-bottom: 20px; /* Adds margin below buttons */
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px; /* Adds space between buttons if you add more */
}

button {
  background-color: #354f9c; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition-duration: 0.4s;
}


button:active,
button:focus {
  outline: none;
}

.show-translation-button {
  display: inline-block;
  width: 140px;
  height: 40px;
  line-height: 40px; /* Aligns text vertically in the button */
  text-align: center; /* Aligns text horizontally */
}