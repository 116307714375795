.flashcard-results-container {
    border: 1px solid black;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    max-width: 600px;
    margin: auto;
}

.flashcard-results-title {
    text-align: center;
    margin-bottom: 20px;
}

.flashcard-results-content {
    display: flex;
    justify-content: space-between;
}

.flashcard-results-section {
    flex: 1;
    margin-right: 10px;
}

.flashcard-results-section:last-child {
    margin-right: 0;
}

.flashcard-results-section-title {
    text-align: center;
    padding: 10px 0;
    border-radius: 5px;
    color: white;
}

.flashcard-results-section-title.learned {
    background-color: #007BFF;
}

.flashcard-results-section-title.proficient {
    background-color: #28a745;
}

.flashcard-results-section-title.mastered {
    background-color: #dc3545;
}

.flashcard-results-list {
    list-style-type: none;
    padding: 0;
}

.flashcard-results-item {
    padding: 5px;
    border-bottom: 1px solid #ccc;
}

.flashcard-results-button {
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    display: block;
    width: 100%;
}
