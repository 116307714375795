.vocabulary-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.vocabulary-box {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.proficiency-input {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.proficiency-input label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.proficiency-input input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.proficiency-input button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.proficiency-input button:hover {
    background-color: #0056b3;
}