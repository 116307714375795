
.lesson-container {
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #000000; /* Add border with your desired color */
  }
  
  .lesson-container h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .lesson-container .objective {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h3 {
    color: #666;
    margin-bottom: 10px;
  }
  
  .section p {
    font-size: 14px;
    line-height: 1.6;
    white-space: pre-wrap; /* Ensure new lines and spacing are preserved */
  }

  .lesson-container {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .section h3 {
    color: #333;
  }
  
  .grammar-comparisons button {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .grammar-comparisons button:hover {
    color: #666;
  }
  
  .comparison-section {
    margin-top: 10px;
  }
  
  .comparison-section h4 {
    margin-top: 10px;
    color: #444;
  }